import "./plugins/jquery-exitintent.min.js";
import timeCounter from "./components/time-counter";
import breakpoints from "./components/breakpoints";

$(document).ready(function(){
    timeCounter.initDataSelectors();
    //Notification Close Cookies
    $('.clientAlertModal').on('hide.bs.modal', function(){
        var isChecked = $(this).find('#checkClose').is(':checked');
        if( isChecked ){
            var days, date, expires, name;
            name = $(this).attr('id');
            days = $(this).data('close-days');
            if( days ){
                date = new Date();
                date.setDate(date.getDate()+days);
                expires = "expires="+date.toUTCString();
            }else{
                expires = "";
            }
            document.cookie = `${name}=; ${expires}`;
        }
    });

    $('.clientAlert').on('close.bs.alert', function(){
        var days, date, expires, name;
        name = $(this).attr('id');
        days = $(this).data('close-days');
        if( days ){
            date = new Date();
            date.setDate(date.getDate()+days);
            expires = "expires="+date.toUTCString();
        }else{
            expires = "";
        }
        document.cookie = `${name}=; ${expires}`; 

        if ($(this).is('[data-alert-floating]')) {
            const alertFloatingClosed = new CustomEvent('alertFloatingClosed');
            document.dispatchEvent(alertFloatingClosed);
        } 
    });
  
    let topNotification = $('[data-alert-floating]');
    let topNotificationHeight = $('[data-alert-floating]').outerHeight();
    if(topNotification){
        $('body').addClass('floating-notification');

        topNotificationHeight = topNotification.outerHeight();
        topNotification.css('top', -1 * topNotificationHeight);
        topNotification.css('display', 'block');
        topNotification.animate(
            {
                top: '0px'
            },
            1000
        );
        let banner = $('[data-site-banner]:not(.banner-cms):first');
        let appMain = $('.lagom-layout-top .app-main');
        let navbarHeight = $('[data-site-navbar]').outerHeight();
        let mobileNavbarHeight = $('#header').outerHeight();

        if (breakpoints.is('desktop')){}
        else{
            $('#header').animate(
                {
                    'margin-top': topNotificationHeight,
                },
                1000
            );
            $('.nav-mobile-dropdown #main-menu').css('top', topNotificationHeight + mobileNavbarHeight + 24);
        }
        if($('[data-site-navbar-left]').length){}
        else{
            if(navbarHeight > 0) {
                banner.css('margin-top',  - navbarHeight + topNotificationHeight);
                banner.css('padding-top',  navbarHeight);
                appMain.css('margin-top',  - navbarHeight + topNotificationHeight);
                appMain.css('padding-top',  navbarHeight);
            }
            else {
                banner.css('margin-top',  navbarHeight);
                banner.css('padding-top',  -navbarHeight + topNotificationHeight);
                appMain.css('margin-top',  navbarHeight);
                appMain.css('padding-top',  -navbarHeight + topNotificationHeight);
            }
        }
        $('.lagom-layout-top .app-nav').animate(
            {
                top: topNotificationHeight,
            },
            1000
        );
        $('.lagom-layout-left .app-main, .lagom-layout-left-wide .app-main').animate(
            {
                'margin-top': topNotificationHeight,
            },
            1000
        );
        
        $(window).resize(function(){
            clearTimeout(floatingNotificationTimeout);
            var floatingNotificationTimeout = setTimeout(function(){
                topNotificationHeight = topNotification.outerHeight();
                $('.lagom-layout-top .app-nav').css('top', topNotificationHeight);
                $('.lagom-layout-left .app-main, .lagom-layout-left-wide .app-main').css('margin-top', topNotificationHeight);
                if (breakpoints.is('desktop')) {
                    $('#header').css('margin-top', '');
                    banner.css('padding-top', '');
                    appMain.css('padding-top', '');
                }else{
                    $('#header').css('margin-top', topNotificationHeight);
                    banner.css('padding-top', '');
                    appMain.css('padding-top', '');
                    $('.nav-mobile-dropdown #main-menu').css('top', topNotificationHeight + mobileNavbarHeight + 24);
                }
                if($('[data-site-navbar-left]').length){}
                else{
                    if(navbarHeight > 0) { 
                        banner.css('margin-top',  - navbarHeight + topNotificationHeight);
                        banner.css('padding-top',  navbarHeight);
                        appMain.css('margin-top',  - navbarHeight + topNotificationHeight);
                        appMain.css('padding-top',  navbarHeight);
                    }
                    else {
                        banner.css('margin-top',  navbarHeight);
                        banner.css('padding-top',  -navbarHeight + topNotificationHeight);
                        appMain.css('margin-top',  navbarHeight);
                        appMain.css('padding-top',  -navbarHeight + topNotificationHeight);
                    }
                }
            },100);
        });   
        $(document).on('alertFloatingClosed', function(){ 
            topNotificationHeight = 0;
            $('#header').css('margin-top', '');
            $('.lagom-layout-top .app-nav').css('top', '');
            $('.lagom-layout-left .app-main, .lagom-layout-left-wide .app-main').css('margin-top', '');
            banner.css('margin-top',  '');
            banner.css('padding-top',  '');
            appMain.css('margin-top',  '');
            appMain.css('padding-top',  '');
            $('[data-sidebar-sticky]').css('top', '');
            $('.nav-mobile-dropdown #main-menu').css('top', '');
        })
    }

    // Modal show first 
    var firstModalDelay = $('.clientAlertModalOnLoad').first().data('onload-delay');
    setTimeout(function(){
        $('.clientAlertModalOnLoad').first().modal('show');
    }, firstModalDelay);

    // Modal show next
    $('.clientAlertModalOnLoad').on('hidden.bs.modal', function(){ 
        var nextEl = $(this).next();
        while(!nextEl.hasClass('clientAlertModalOnLoad')){
            nextEl = nextEl.next();
            if(!nextEl.hasClass('clientAlertModalOnLoad') && !nextEl.hasClass('clientAlertModalOnExit')){
                break;
            }
        };

        var nextElDelay = nextEl.data('onload-delay');
        if (nextEl.length && nextEl.hasClass('clientAlertModalOnLoad')){
            setTimeout(function(){
                nextEl.modal('show');
            }, nextElDelay);
        }
    });

    $('.clientAlertModalOnExit').on('hidden.bs.modal', function(){
        var nextEl = $(this).next();

        while(!nextEl.hasClass('clientAlertModalOnExit')){
            nextEl = nextEl.next();
            if(!nextEl.hasClass('clientAlertModalOnLoad') && !nextEl.hasClass('clientAlertModalOnExit')){
                break;
            }
        };

        if (nextEl.length && nextEl.hasClass('clientAlertModalOnExit')){
            nextEl.modal('show');
        }
    });
});

$.exitIntent('enable');
$(document).bind('exitintent', function() {
    $('.clientAlertModalOnExit').first().modal('show');
});