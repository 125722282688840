const breakpoints = {
    xs: 0,
    sm: 480,
    md: 768,
    lg: 992,
    xl: 1200
};

let activeViewport = 'desktop'
function enquireRegister(min, max, options) {
    let mediaQuery = ``;
    let deferSetup = options.deferSetup;

    if (min && max) {
        mediaQuery = `screen and (min-width:${breakpoints[min]}px) and (max-width:${breakpoints[max]}px)`
    }
    if (min && !max) {
        mediaQuery = `screen and (min-width:${breakpoints[min]}px)`
    }

    if (!min && max) {
        mediaQuery = `screen and (max-width:${breakpoints[max]}px)`
    }

    if (options.deferSetup) {
        deferSetup = options.deferSetup;
    }

    enquire.register(mediaQuery, {
        match: function () {
            if (typeof options.match === 'function') {
                options.match();
            }
        },
        unmatch: function () {
            if (typeof options.unmatch === 'function') {
                options.unmatch();
            }
        },
        setup: function () {
            if (typeof options.setup === 'function') {
                options.setup();
            }
        },
        deferSetup: deferSetup,
        destroy: function () {
            if (typeof options.destroy === 'function') {
                options.destroy();
            }
        }
    });
}

function checkViewport() {

    enquireRegister('xs', 'md', {
        match() {
            activeViewport = 'mobile'
        }
    })

    enquireRegister('md', 'lg', {
        match() {
            activeViewport = 'tablet'
        }
    })

    enquireRegister('lg', null, {
        match() {
            activeViewport = 'desktop'
        }
    })
}

checkViewport();
export default {
    between: function (min, max, options) {
        enquireRegister(min, max, options)
    },
    down: function (val, options) {
        enquireRegister(null, val, options)
    },
    up: function (val, options) {
        enquireRegister(val, null, options)
    },
    is(value) {
        if (value == activeViewport) {
            return true
        } else {
            return false
        }
    }
}