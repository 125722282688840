class timeCounter{ 
    constructor(container){
        this.container = container;
        this.timestamp = this.container[0]?.attributes['data-timestamp']?.value;
        this.days = this.container[0].querySelectorAll('[data-days] .timer-box-num .timer-box-num-text');
        this.hours = this.container[0].querySelectorAll('[data-hours] .timer-box-num .timer-box-num-text');
        this.minutes = this.container[0].querySelectorAll('[data-minutes] .timer-box-num .timer-box-num-text');
        this.seconds = this.container[0].querySelectorAll('[data-seconds] .timer-box-num .timer-box-num-text');
        this.timeCounter();    
    }
    timeCounter(){
        let now =  Math.floor(Date.now() / 1000),
            difference = this.timestamp - now,
            days = Math.floor(difference / (60 * 60 * 24)),
            hours = Math.floor((difference % (60 * 60 * 24)) / (60 * 60)),
            minutes = Math.floor((difference % (60 * 60)) / 60),
            seconds = difference % 60;
            if (days < 10){
                days = '0'+days;
            }
            if (hours < 10){
                hours = '0'+hours;
            }
            if (minutes < 10){
                minutes = '0'+minutes;
            }
            if (seconds < 10){
                seconds = '0'+seconds;
            }
            this.days[0].innerText = days;
            this.hours[0].innerText = hours;
            this.minutes[0].innerText = minutes;
            this.seconds[0].innerText = seconds;
            this.initTimer();
            this.container.removeClass('is-loading');
    }
    initTimer() {
        setTimeout(() => {
            this.timeCounter();
        }, 1000)    
    }
}

function initDataSelectors() {
    $('[data-client-notification-time-counter]').each(function () {
        new timeCounter($(this));
    });
}

const init = {
    initDataSelectors,
};

export default init;